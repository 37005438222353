import axios from 'axios'
import router from '../router'


axios.defaults.timeout = 50000;                        //响应时间

axios.defaults.baseURL = 'https://ww168.cn/';


//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
   //config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    /* config.headers = {
       'Content-Type':'application/x-www.form-urlencoded' //配置请求头
     } */
    const token =localStorage.getItem('token');//这里取token之前，你肯定需要先拿到token,存一下

     if(token){
        config.params = {'token':token} //如果要求携带在参数中
        config.headers.token= token; //如果要求携带在请求头中
      }
    return config
},(error) =>{
  return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
  //对响应数据做些事
  if(!res.data.success){
    return Promise.resolve(res);
  }

  return res;
}, (error) => {
	if(error.response.status=='401'){
		console.log(123123123)
		//跳转到登录页面
		router.push('password')
		return
	}
  return Promise.reject(error);
});

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url,params)
      .then(response => {
		  if(response){
			   resolve(response.data);
		  }

      }, err => {
        reject(err);
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    axios.get(url, {params: param})
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export default {
  fetchPost,
  fetchGet,
}
