import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
	  meta:{
		  title: '功能授权',
		  keepAlive: false

	  }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutUs.vue')
  },
	{
	  path: '/vip',
	  name: 'vip',
	  component: () => import('../views/vip.vue')
	},
	{
	  path: '/database',
	  name: 'database',
	  component: () => import('../views/database.vue')
	},
	{
	  path: '/list',
	  name: 'list',
	  component: () => import('../views/list.vue')
	},
	{
	  path: '/detail',
	  name: 'detail',
	  component: () => import('../views/detail.vue')
	},
	{
	  path: '/password',
	  name: 'password',
	  component: () => import('../views/password.vue')
	},
	{
		path: '/info',
		name: 'info',
		component: () => import('../views/info.vue')
	},
	{
		path: '/tips',
		name: 'tips',
		component: () => import('../views/tips.vue')
	},
	{
		path: '/vipList',
		name: 'vipList',
		component: () => import('../views/vipList.vue')
	},
	{
		path: '/freeList',
		name: 'freeList',
		component: () => import('../views/freeList.vue')
	},{
		path: '/take',
		name: 'take',
		component: () => import('../views/take.vue')
	}
]

const router = new VueRouter({
  routes
})

export default router
