<template>
	<div class="home">
		<navBar></navBar>

		<div class="name">梓南 🍟 高端真人线下约会</div>
		<div class="one">
			<div class="video">
				<img style="width: 100%;" :src="video"></img>
			</div>
			<div class="content">

				<div>
					<h1>🔍 简介</h1>
					<div v-html="content1"></div>
					<ul>
						<li @click="goZN">了解更多请查看 <a>@约会指南</a></li>
					</ul>


				</div>
			</div>
		</div>
		<div class="zn">
			<h1>免费看资料库</h1>
			<div class="card" @click="goTo">
				<div class="ctitle"> 💗 真人资料库</div>
				<p>梓南留言：3K资料库限时开放中👇</p>
				<div class="title">
					<img src="../assets/logo.png" />
					<div>ZinanClub</div>
				</div>
			</div>
			<h1>❓ 约会快速 Q&A</h1>
			<div v-html="content2"></div>
		</div>
		<div class="zn">
			<h1>入会咨询安排联系电报客服</h1>
			<div class="card" style="display: flex;justify-content: space-between;" @click="kefu">
				<div>
					<div class="ctitle"> 梓南 🍟 客服 (真人值班)</div>
					<p>You can contact @ZinanClub right away.</p>
					<div class="title">
						<img src="../assets/logo.png" />
						<div>ZinanClub</div>
					</div>
				</div>
				🍟
			</div>
		</div>
		<div class="footer">梓南 🍟 高端真人线下约会</div>
	</div>
</template>

<script>
	import navBar from '../components/navBar.vue'
	export default {
		components: {
			navBar
		},
		data() {
			return {
				content1: '',
				content2: '',
				video: ''
			}
		},
		created() {
			this.loadData()
		},
		methods: {

			async loadData() {
				const res = await this.http.fetchPost('/api/index/text_config')
				this.content1 = res.data.find(e => e.id == 1).content
				this.content2 = res.data.find(e => e.id == 2).content
				const res2 = await this.http.fetchPost('/api/index/video_config')
				this.video = res2.data[0].image
			},
			kefu() {
				window.open('http://t.me/Coco911s', '_blank')
			},
			goTo() {
				this.$router.push('password')
			},
			goZN() {
				this.$router.push('vip')
			},
		}

	}
</script>
<style lang="less" scoped>
	@media screen and (max-width: 960px) {
		.myClass {
			list-style-type: circle;
		}

		li {
			line-height: 34px;
			font-size: 1.2rem;
		}

		.home {

			.zn {
				margin-top: 30px;
			}

			.name {
				font-size: 36px;
				font-weight: bold;
				margin-top: 100px;
				margin-bottom: 20px;
				text-align: center;
			}

			.one {
				.video {
					width: 100%;
					display: flex;
					justify-content: center;
				}

				.content {
					width: 100%;


				}
			}
		}
	}
	@media screen and (min-width: 960px) {
		.myClass {
			list-style-type: circle;
		}

		li {
			line-height: 34px;
			font-size: 1.2rem;
		}

		.home {

			.zn {
				margin-top: 80px;
			}

			.name {
				font-size: 54px;
				font-weight: bold;
				margin-top: 120px;
				margin-bottom: 40px;
				text-align: center;
			}

			.one {
				.video {
					width: 100%;
					display: flex;
					justify-content: center;
				}

				.content {
					width: 100%;


				}
			}
		}
	}
</style>
