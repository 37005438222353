<template>
	<div class="navTop">
		<div style="font-weight: bold;">梓南 🍟 高端真人线下约会</div>
		<div class="nav">
			<div class="item" @click="toHome">主页</div>
			<div class="item" @click="toZr">真人 💗 资料库</div>
			<div class="item" @click="toZn">约会指南</div>
			<div class="item" @click="toVip">会员体系</div>
			<div class="item" @click="toBase">会员专区</div>
			<el-button type="primary" style="background-color: #0278e9;" @click="kefu">🌟安排</el-button>
			<div class="wap" @click.stop="showNav=true"><img src="../assets/daohang.png" /></div>
			<div class="qie" @click.stop="changeStyle"><img src="../assets/ty.png" v-if="shows"/><img src="../assets/yl.png" v-else/></div>
		</div>
		<div class="navWap" v-if="showNav">

			<div class="item" style="display: flex;justify-content: space-between;" @click="toHome">
				<div>主页</div>
				<img src="../assets/close.png" style="width: 1em;height: 1em;" @click="showNav=false" />
			</div>
			<div class="item" @click="toZr">真人 💗 资料库</div>
			<div class="item" @click="toZn">约会指南</div>
			<div class="item" @click="toVip">会员体系</div>
			<div class="item" @click="toBase">会员专区</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'navBar',
		data() {
			return {
				showNav: false,
				shows: false
			}
		},
		beforeCreate() {
			if (localStorage.getItem('style') == 'yl') {
				this.shows=true
				document.documentElement.style.setProperty("--theme_bg_color", '#000');
				document.documentElement.style.setProperty("--theme_fs_color", '#fff');
				document.documentElement.style.setProperty("--theme_bj_color", '#353535');
				
			} else {
				this.shows=false
				document.documentElement.style.setProperty("--theme_bg_color", '#fff');
				document.documentElement.style.setProperty("--theme_fs_color", '#000');
				document.documentElement.style.setProperty("--theme_bj_color", '#e2e2e2');
			}
		},
		methods: {
			changeStyle() {
				if (localStorage.getItem('style') == 'yl') {
					this.shows=true
					localStorage.setItem('style', 'ty')
					document.documentElement.style.setProperty("--theme_bg_color", '#fff');
					document.documentElement.style.setProperty("--theme_fs_color", '#000');
					document.documentElement.style.setProperty("--theme_bj_color", '#e2e2e2');
					
				} else {
					this.shows=false
					document.documentElement.style.setProperty("--theme_bg_color", '#000');
					document.documentElement.style.setProperty("--theme_fs_color", '#fff');
					document.documentElement.style.setProperty("--theme_bj_color", '#353535');
					localStorage.setItem('style', 'yl')
				}

			},
			kefu() {
				window.open('http://t.me/Coco911s', '_blank')
			},
			toZn() {

				if (this.$route.name == 'about') {
					this.showNav = false
					return
				}

				this.$router.push('about')
			},
			toVip() {
				if (this.$route.name == 'vip') {
					this.showNav = false
					return
				}
				this.$router.push('vip')
			},
			toHome() {
				if (this.$route.name == 'home') {
					this.showNav = false
					return
				}
				this.$router.push('/')
			},
			toZr() {
				if (this.$route.name == 'info') {
					this.showNav = false
					return
				}
				this.$router.push('info')
			},
			toBase() {
				if (this.$route.name == 'database') {
					this.showNav = false
					return
				}
				this.$router.push('database')
			},
			nav() {

			}
		}
	}
</script>

<style lang="less" scoped>
	@media screen and (max-width: 960px) {
		.navTop {
			width: 90%;
			background-color: var(--theme_bg_color);
			padding: 5px 8px;
			box-sizing: border-box;
			border-radius: 10px;
			position: fixed;
			top: 1rem;
			left: 50%;
			transform: translate(-50%);
			box-shadow: 0px 2px 4px 0px rgba(15, 34, 59, 0.08);
			display: flex;
			align-items: center;
			justify-content: space-between;
			z-index: 101;

			.qie {
				display: none !important;
			}

			.navWap {
				width: 100%;
				position: absolute;
				top: 0px;
				left: 0;
				background-color: var(--theme_bg_color);
				border-radius: 5px;

				.item {
					padding: 15px;
					color: var(--theme_fs_color)
				}
			}

			.nav {
				display: flex;
				align-items: center;

				.item {
					display: none;
				}

				img {
					margin-left: 15px;
					width: 1.2em;
					height: 1.2em
				}
			}
		}
	}

	@media screen and (min-width: 960px) {
		.navTop {
			width: 100%;
			max-width: 1280px;
			background-color: var(--theme_bg_color);
			height: 60px;
			padding: 10px 30px;
			box-sizing: border-box;
			border-radius: 10px;
			position: fixed;
			top: 1rem;
			left: 50%;
			transform: translate(-50%);
			box-shadow: 0px 2px 8px 0px rgba(15, 34, 59, 0.08);
			display: flex;
			align-items: center;
			justify-content: space-between;
			z-index: 10;

			.wap {
				display: none !important;
			}

			.navWap {
				display: none !important;
			}

			.qie {
				img {
					width: 2em;
					height: 2em;
					margin-left: 10px;
					margin-right: -15px;
					cursor: pointer;
				}
			}

			.nav {
				display: flex;
				align-items: center;

				.item {
					padding: 0 15px;
					cursor: pointer;

				}
			}
		}
	}
</style>